@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
    display: flex;
    align-items: center;
    gap: 10px;
    // margin: 15px 15px -5px 15px;
}

.barContainer {
    height: 8px;
    background: #a4ddbc;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
}
.progressBar {
    background: $primary-color;
    border-radius: 4px;
    height: 100%;
}
.icon {
    height: 25px;
    width: 25px;
}
