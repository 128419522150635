$logoSize: 75px;
$nameSize: 1.1rem;
$cardWidth: 15rem;

.container {
    width: $cardWidth;
    max-width: 100vw;

    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px 20px;
    flex: 1;
    // &:hover {
    //     transform: scale(1.02);
    // }
    // &:active {
    //     transform: scale(0.98);
    // }
    // transition: transform 0.1s ease-in-out;

    h5 {
        display: inline-block;
        align-self: center;
        align-items: center;
        text-align: center;
        width: 15rem;
        line-height: 1.5rem;
        font-size: $nameSize;
        color: "#333333";
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        padding: 0px 5px;
        margin: 0px;

        // Only display one line if browser doesn't support -webkit-box
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @supports (display: -webkit-box) {
        h5 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
        }
    }

    img {
        height: $logoSize;
        width: $logoSize;
        background-color: white;
        border-radius: 10px;
        object-fit: contain;
    }
    .imagePlaceholder {
        height: $logoSize;
        width: $logoSize;
        background-color: white;
        border-radius: 10px;
        object-fit: contain;
    }

    .companyNameContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;

        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .companyNamePlaceholder {
        height: 1.25rem;

        margin-top: 0.875rem;
        margin-bottom: 0.875rem;

        width: 10rem;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        background-color: #e5e5e5;
        border-radius: 3px;

        align-self: center;
    }
    .companyNamePlaceholderContainer {
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-self: center;
    }
}

.leafContainer {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;

    .laufidLeaf {
        color: #f3f3f3;
        height: 1.7rem;
        width: 2.5rem;

        justify-self: center;
    }
}
