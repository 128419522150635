@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1030px;
    justify-content: center;
}
