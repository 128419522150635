@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
}

.titles {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
